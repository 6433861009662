/**
 * 酒店相关信息
 */
import { DiffDate, AddDate } from "@/services/date.js"
import { FetchCityCode } from "@/services/aircraft";
import moment from "moment";
import store from '../index'


const state = {
    aircraftOrderInfo: { // 订票信息
        date: [], // 出发日期
        from: '', // 出发地
        fromCode: '',
        to: '', // 目的地
        toCode: ''
    },
    aircraftType: {
        isSingle: true, // 是否单程
        step: 0, // 阶段（往返有效）
    },
    aircraftList: [], // 机票列表
    aircraftTicket: [{}, {}], // 机票信息[去程， 返程]
    aircraftOrderChange: {}, // 改签单
    aircraftOriginOrderChange: {}, // 改签单的原单
    aircraftOriginPrice: 0, // 原单价格
    aircraftTempTime: [], // 缓存往返时间
}

const mutations = {
    // 修改缓存往返时间
    SET_AircraftTempTime: (state, info) => {
        state.aircraftTempTime = info
    },
    // 票据信息
    SET_AIRCRAFTORDERINFO: (state, info) => {
        info.date = info.date.map(item => moment(item).format("YYYY-MM-DD"))
        info.fromCode = FetchCityCode(info.from)
        info.toCode = FetchCityCode(info.to)
        state.aircraftOrderInfo = info
    },
    SET_FROM: (state, info) => {
        if (info.hasOwnProperty('name')) {
            state.aircraftOrderInfo.from = info.name
            state.aircraftOrderInfo.fromCode = FetchCityCode(info.name)
        }
        else {
            state.aircraftOrderInfo.from = info
            state.aircraftOrderInfo.fromCode = FetchCityCode(info)
        }
    },
    SET_TO: (state, info) => {
        if (info.hasOwnProperty('name')) {
            state.aircraftOrderInfo.to = info.name
            state.aircraftOrderInfo.toCode = FetchCityCode(info.name)
        }
        else {
            state.aircraftOrderInfo.to = info
            state.aircraftOrderInfo.toCode = FetchCityCode(info)
        }
    },
    SET_DATE: (state, info) => {
        info = info.map(item => moment(item).format("YYYY-MM-DD"))
        if (DiffDate(info[1], info[2]) < 0)
            info[1] = AddDate(info[0])
        state.aircraftOrderInfo.date = info
    },

    // 单程|往返
    SET_SINGLE: (state, info) => {
        state.aircraftType.isSingle = info
    },
    SET_STEP: (state, info) => {
        state.aircraftType.step = info
    },
    SET_TYPE: (state, info) => {
        state.aircraftType = info
    },

    // 列表
    SET_AIRCRAFTLIST: (state, info) => {
        state.aircraftList = info
    },
    APPEND_AIRCRAFTLIST: (state, info) => {
        state.aircraftList.push(...info)
    },

    // 票
    SET_AIRCRAFTTICKET: (state, info) => {
        state.aircraftTicket[info.index] = info.data
    },

    // 改签单
    SET_AIRCRAFTORDERCHANGE: (state, info) => {
        state.aircraftOrderChange = info
    },
    // 改签单的原单
    SET_AIRCRAFTORIGIN: (state, info) => {
        state.aircraftOriginOrderChange = info
    },
    SET_AircraftOriginPrice: (state, info) => {
        state.aircraftOriginPrice = info
    },
}

const actions = {
    setAircraftTempTime({ commit }, info) {
        info = info.map((e) => {
            return moment(e).format("YYYY-MM-DD");
        });
        commit('SET_AircraftTempTime', info)
    },
    // 票据信息
    setAircraftOrderInfo({ commit }, info) {
        info.date = CheckTime(info.date)
        commit('SET_AIRCRAFTORDERINFO', info)
    },
    // 清空aircraftOrder信息
    cleanAircraftOrderInfo({ commit }) {
        commit('SET_AIRCRAFTORDERINFO', {
            date: [], // 出发日期
            from: '', // 出发地
            fromCode: '',
            to: '', // 目的地
            toCode: ''
        })
    },
    setAircraftOrderFrom({ commit }, info) {
        commit('SET_FROM', info)
    },
    setAircraftOrderTo({ commit }, info) {
        commit('SET_TO', info)
    },
    setAircraftOrderDate({ commit }, info) {
        info = CheckTime(info)
        commit('SET_DATE', info)
    },

    // 单程|往返
    setAircraftOrderSingle({ commit }, info) {
        let f = (info == '单程')
        commit('SET_SINGLE', f)
    },
    setAircraftStep({ commit }, info) {
        commit('SET_STEP', info)
    },
    setAircraftType({ commit }, info) {
        commit('SET_TYPE', info)
    },


    // 列表
    appendAircraftList({ commit }, info) {
        commit('APPEND_AIRCRAFTLIST', info)
    },

    setAircraftList({ commit }, info) {
        commit('SET_AIRCRAFTLIST', info)
    },

    // 票
    setAircraftTicket({ commit }, info) {
        commit('SET_AIRCRAFTTICKET', info)
    },

    setAircraftOrderChange({ commit, state }, info) {
        info.peoList.forEach((e) => {
            store.getters.personList.forEach(element => {
                if (element.usercode == e.passengerId)
                    e.stand = element.stand
            });
        })
        commit('SET_AIRCRAFTORDERCHANGE', info)
    },
    setAircraftOrigin({ commit }, info) {
        commit('SET_AIRCRAFTORIGIN', info)
    },

    setAircraftOriginPrice({ commit }, info) {
        commit('SET_AircraftOriginPrice', info)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

/**
 * 整理机票列表
 * @param {Array} arr 
 */
function OrganizeList(arr = []) {
    let dataArr = []
    arr.forEach(element => {
        try {
            let deadArr = []
            // 如果是数组遍历,过滤掉无座机票，保留无票坐席
            element.data = element.data.filter(item => {
                if (!item.flightSeat) {
                    deadArr.push(item)
                    return false
                }
                let arr = item.flightSeat.filter(seat => seat.number > 0)
                return arr.length > 0
            })
            dataArr.push(...element.data, ...deadArr)
        } catch (error) { }
    });
    return dataArr
}

/**
 * 检查aircraftOrderInfo.date日期是否合规
 * @param {Array<Date, Date>} arr 
 */
function CheckTime(arr = []) {
    if (0 in arr) {
        // 如果小于当日
        let difcur = DiffDate(new Date(), arr[0])
        if (difcur < 0) {
            arr[0] = AddDate(new Date(), 0)
        }
        // 如果返程小于等于去程，返程等于去程后一天
        if (DiffDate(arr[0], arr[1]) <= 0) {
            arr[1] = AddDate(arr[0], 1);
        }
    }
    return arr;
}