<template>
  <!-- 行程单信息，凸显内控，每次使用都会更新项目余额 -->
  <van-panel title="行程信息" v-if="travelInfo">
    <van-cell title="申请单号" v-if="travelInfo.appId" :value="travelInfo.appId" class="auto-cell" />
    <van-cell title="行程限额" class="auto-cell">
      <template #default>
        <span>
          ￥{{travelInfo.appPrice}}
          <span v-if="travelInfo.appSurplus">
            (剩余
            <span
              :style="'color:'+(travelInfo.appSurplus>=totalPrice?'green':'red')"
            >￥{{travelInfo.appSurplus}}</span>)
          </span>
        </span>
      </template>
    </van-cell>
    <van-cell
      title="申请事由"
      v-if="travelInfo.appReason"
      :value="travelInfo.appReason"
      class="auto-cell"
    />
    <van-cell
      title="经办人"
      v-if="travelInfo.loginUser.username"
      :value="travelInfo.loginUser.username"
      class="auto-cell"
    />
    <van-cell
      title="出行性质"
      v-if="travelInfo.travelType"
      :value="travelInfo.travelType"
      class="auto-cell"
    />
    <van-cell title="经费项目" v-if="travelInfo.appProject" class="auto-cell">
      <template #default>{{travelInfo.appProject}}</template>
    </van-cell>
  </van-panel>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "infoList",
  props: {
    // 总票价
    totalPrice: {
      default: 0,
    },
  },
  // 每次用到这个组件都更新一下余额
  async mounted() {
    await this.$store.dispatch("project/updateAppPrice");
    this.$forceUpdate();
  },
  computed: {
    ...mapGetters(["travelInfo"]),
  },
  watch: {
    async travelInfo(val) {
      await this.$store.dispatch("project/updateAppPrice");
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="less" src="@/styles/orderHeadCommon.less" scoped></style>
<style lang="less" scoped>
.icon-idCard {
  color: #fff;
}
.stop-station {
  font-size: 12px;
  line-height: 22px;
}
.stop-station,
.timetable {
  text-align: center;
}
.time-line {
  line-height: 20px;
  div {
    font-size: 12px;
    line-height: 12px;
    border: 1px solid #fff;
    display: inline-block;
    padding: 4px 6px;
    border-radius: 17px;
  }
  span {
    transform: translateY(-2px) translateX(-3px);
    display: inline-block;
  }
  .left-line {
    transform: scaleX(2.5) translateX(-2px);
    color: #ecf2fd;
    font-size: 19px;
  }
  .right-line {
    transform: translateX(-1px);
  }
}
</style>