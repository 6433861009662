<template>
  <div style="width:100%;overflow:hidden;position: relative">
    <van-row class="head-background" gutter="20">
      <van-col span="6">
        <div class="head-img">
          <van-image width="100%" round src="/companyLogo.png" />
        </div>
      </van-col>
      <van-col span="18" class="text-cont">
        <p class="list-title">{{travelInfo.loginUser.username}}</p>
        <p class="list-text">性别：男</p>
      </van-col>
    </van-row>

    <div style="margin-top: 6.6rem"></div>

    <van-row class="itembk">
      <van-cell class="auto-cell" icon="debit-pay" :border="false" v-model="travelInfo.loginUser.idCard" :title="travelInfo.loginUser.idType" />
      <van-cell class="auto-cell" icon="phone-o" :border="false" v-model="travelInfo.loginUser.tel" title="手机号" />
      <van-cell class="auto-cell" icon="manager-o" :border="false" v-model="travelInfo.loginUser.travelLevel" title="职位" />
      <van-cell class="auto-cell" icon="coupon-o" :border="false" v-model="travelInfo.loginUser.usercode" title="职工号" />
    </van-row>

    <div class="gap"></div>

    <van-row class="itembk">
      <van-cell title="差旅政策" icon="label-o" is-link to="policy" />
      <van-cell v-if="switch_feedback" title="提交反馈" icon="smile-comment-o" is-link :url="`${switch_feedbackurl}feedForm?userId=${travelInfo.loginUser.usercode}&userName=${travelInfo.loginUser.username}&${switch_feedbackschool}`" />
      <van-cell v-if="switch_feedback" title="反馈历史" icon="question-o" is-link :url="`${switch_feedbackurl}allHistroy?userId=${travelInfo.loginUser.usercode}&userName=${travelInfo.loginUser.username}&${switch_feedbackschool}`" />
    </van-row>

    <div class="gap"></div>

    <a :href="'tel:'+getPhoneCall(item)" v-for="(item,index) in phoneList" :key="index">
      <van-row class="itembk">
        <van-cell :title="item+'客服热线'" label="24小时专属客服">
          <template #right-icon>
            <span class="list-text icon-phone">
              立即致电
              <van-icon name="phone" style="padding-left:4px" />
            </span>
          </template>
          <template #icon>
            <Logo :corp="item" class="icon-service" />
          </template>
        </van-cell>
      </van-row>
      <div class="gap"></div>
    </a>

    <router-link class="memorabilia list-text-big " to="memorabilia">版本大事记</router-link>
    <div class="gap"></div>
    <div style="width:100%;height:50px"></div>
    <!-- <van-button
      style="border-radius: 8px;font-size: 16px"
      class="head-background"
      @click="show=true,openEdit()"
    >修改信息</van-button>-->

    <!-- 人员信息-弹出层 -->
    <!-- <van-dialog closeOnClickOverlay v-model="show" title="修改人员信息" show-cancel-button :beforeClose="subimtTel" class="editInfo">
      <van-form ref="editTel">
        <div class="gap"></div>
        <van-field v-model="Card" center clearable placeholder="请输入证件号码" :rules="[{ required: true, message: '请输入证件号码' }]">
          <template #label>
            <el-select v-model="idType" slot="prepend" placeholder="请选择">
              <el-option label="身份证" value="身份证"></el-option>
              <el-option label="护照" value="护照"></el-option>
            </el-select>
          </template>
        </van-field>
        <van-field v-model="phone" type="digit" label="手机号" name="validator" placeholder="请输入手机号" :rules="[{ validator, message: '请输入手机号' }]" />
        <br />
      </van-form>
    </van-dialog> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Logo from "@/components/Logo";
import {
  fetchPhone,
  TrainServiceProvider,
  AircraftServiceProvider,
  HotelServiceProvider,
  NeedFeedback,
  FeedbackUrl,
  FeedbackSchool
} from "@/common/config";
export default {
  computed: {
    ...mapGetters(["travelInfo"])
  },
  components: { Logo },
  data() {
    return {
      switch_feedback: NeedFeedback, // 反馈开关
      switch_feedbackurl: FeedbackUrl, // 反馈路径
      switch_feedbackschool: FeedbackSchool, // 反馈参数
      phoneList: [
        ...new Set([
          ...TrainServiceProvider,
          ...AircraftServiceProvider,
          ...HotelServiceProvider
        ])
      ],
      idType: "身份证", // 编辑框-证件类型
      idCard: "", // 编辑框-证件号
      tel: "", // 编辑框-手机号
      Card: "",
      phone: "",
      show: false
    };
  },
  methods: {
    // 获取电话号
    getPhoneCall(corp) {
      return fetchPhone(corp);
    },
    openEdit() {
      this.phone = this.travelInfo.loginUser.tel;
      this.Card = this.travelInfo.loginUser.idCard;
      this.idType = this.travelInfo.loginUser.idType;
      this.show = true;
    },
    // 手机号校验
    validator(val) {
      return /^1[3456789]\d{9}$/.test(val);
    },

    subimtTel(action, done) {
      if (action === "confirm") {
        this.$refs.editTel
          .validate()
          .then(() => {
            this.travelInfo.loginUser.tel = this.phone;
            this.travelInfo.loginUser.idCard = this.Card;
            this.travelInfo.loginUser.idType = this.idType;
            done();
          })
          .catch(() => {
            done(false);
          });
      } else {
        done();
      }
    }
  },
};
</script>
<style lang="less">
.mmz {
  .editInfo .el-input__inner {
    border: 0;
    padding: 0;
  }
  .el-select-dropdown {
    z-index: 9999 !important;
  }
  .el-input__suffix-inner {
    pointer-events: all;
    position: relative;
    right: 16px;
  }
}
</style>

<style lang="less" scoped>
.head-background {
  color: #fff;
  padding: 1rem 28%;
  box-sizing: border-box;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  height: 9.25rem;
  width: 150%;
  position: absolute;
  top: 0;
  left: -25%;
  .list-title,
  .list-text {
    color: #fff;
  }
}
.head-img {
  background-color: #fff;
  height: calc(25vw - 1rem - 10px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding: 2px;
}
.text-cont {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 3rem;
  box-sizing: border-box;
}
.icon-phone {
  color: #969799;
  display: flex;
  align-items: center;
}
.icon-service {
  font-size: 24px;
  line-height: 42px;
  padding-right: 6px;
}
.itembk {
  width: 95vw;
  margin: 0 auto;
}
.memorabilia{
  color: #1989fa;
  display: table;
  margin: 0 auto;
}
</style>