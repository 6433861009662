import CryptoJS from 'crypto-js';

let util = {};

util.getWeekDay = function (date) {
    let a = ["日", "一", "二", "三", "四", "五", "六"];
    let week = date.getDay();
    let str = "周" + a[week];
    return str;
};
//日期格式化
util.dateFtt = function (fmt, date) {
    //author: meizz
    let o = {
        'M+': date.getMonth() + 1,                 //月份
        'd+': date.getDate(),                    //日
        'h+': date.getHours(),                   //小时
        'm+': date.getMinutes(),                 //分
        's+': date.getSeconds(),                 //秒
        'q+': Math.floor((date.getMonth() + 3) / 3), //季度
        'S': date.getMilliseconds()             //毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
    }
    return fmt
};

//两个时间相差天数 兼容firefox chrome
util.dateDifference = function dateDifference(sDate1, sDate2) {
    var dateSpan,
        tempDate,
        iDays;
    // sDate1 = Date.parse(sDate1);
    // sDate2 = Date.parse(sDate2);
    dateSpan = sDate2 - sDate1;
    dateSpan = Math.abs(dateSpan);
    iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
    return iDays
};

/**
 * 神州浩天加密(Key必须为16位长度)
 * @param sSrc
 * @return
 */
util.szhtEncrypt = function (sSrc) {

    if (!this.isCrypt()) {
        return sSrc
    }
    let keyStr = 'SzHt2020SzHt2020';
    let content = sSrc;
    let key = CryptoJS.enc.Utf8.parse(keyStr);
    let encryptedData = CryptoJS.AES.encrypt(content, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    let encryptedStr = encodeURIComponent(encryptedData.toString());
    return encryptedStr;
};


/**
 * 神州浩天解密(Key必须为16位长度)
 * @param sSrc
 * @return
 * @throws Exception
 */
util.szhtDecrypt = function (sSrc) {

    if (!this.isCrypt()) {
        return sSrc
    }
    let keyStr = 'SzHt2020SzHt2020';
    let content = sSrc;
    let key = CryptoJS.enc.Utf8.parse(keyStr);
    let decryptedData = CryptoJS.AES.decrypt(decodeURIComponent(content), key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    let decryptedStr = decryptedData.toString(CryptoJS.enc.Utf8);
    return decryptedStr;
};


/**
 * 是否加密设置
 */
util.isCrypt = () => true;


util.windowWidth = () => {
    return document.documentElement.clientWidth
};
//======================================================================================================================
export default util;
