import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css'
import 'vant/lib/index.less'
import './styles/style.less'
import './styles/iconfont/iconfont.css'
import './styles/font_xm/iconfont.css'
import './styles/font_929/iconfont.css'
import Vant from 'vant';
import { Toast } from 'vant';
import Moment from 'moment'
import 'moment/locale/zh-cn'
import { Tooltip, Select, Option } from "element-ui";
import '@/permission'

Vue.filter('comverTime', function (data, format) {
  return Moment(data).format(format);
});
Toast.setDefaultOptions({ duration: 5000 });

// Vue.use(anime);
Vue.use(Vant);
Vue.use(Tooltip)
Vue.use(Select)
Vue.use(Option)
Vue.use(Toast);

//暂时用在选择城市后，返回前一个页面，带回城市信息用
Vue.prototype.$bus = new Vue();

// 设置window的title，有默认值。使用方式：window.setTitle(name)
Window.prototype.setTitle = function (title = '神州浩天商旅平台') { window.document.title = title }
// 数字转中文数字
Number.prototype.numberToChinese = function () {
  let number = parseInt(this||0)
  let str = number > 0 ? '' : '负'
  do {
    switch (number%10) {
      case 0:
        str += '零';
        break;
      case 1:
        str += '一'
        break;
      case 2:
        str += '二'
        break;
      case 3:
        str += '三'
        break;
      case 4:
        str += '四'
        break;
      case 5:
        str += '五'
        break;
      case 6:
        str += '六'
        break;
      case 7:
        str += '七'
        break;
      case 8:
        str += '八'
        break;
      case 9:
        str += '九'
        break;
      default:
        str += '十'
        break;
    }
    number = parseInt(number/10)
  } while (number!=0);
  return str.split('').reverse().join('')
}
// 提取字符串中的数字
String.prototype.fliterNumber = function(){
  return this.replace(/[^0-9]/ig,"");
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
