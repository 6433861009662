/**
 * 酒店相关信息
 */
const state = {
    carOrderInfo: { // 订票信息
      date: '', // 出发日期
      destination: "唐山", // 目的地
      keyword: '', //关键词
    },
    carList: [], // 酒店列表
  }
  
  const mutations = {
    SET_CARORDERINFO: (state, info) => {
      state.carOrderInfo = info
    },
    SET_CARLIST: (state, info) => {
      state.carList = info
    },
    SET_FROM: (state, info) => {
      state.carOrderInfo.destination = info
    },
  }
  
  const actions = {
    setcarOrderInfo({ commit }, info) {
      commit('SET_CARORDERINFO', info)
    },
    setcarList({ commit }, info) {
      commit('SET_CARLIST', info)
    },
    setCarOrderFrom({ commit }, info) {
      commit('SET_FROM', info)
    },
  }
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  }