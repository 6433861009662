import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import train from './modules/train'
import hotel from './modules/hotel'
import car from './modules/car'
import aircraft from './modules/aircraft'
import project from './modules/project'
import history from './modules/history'
import persistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [persistedState({ storage: window.sessionStorage })],
  modules: {
    train,
    hotel,
    aircraft,
    car,
    history,
    project
  },
  getters
})

export default store