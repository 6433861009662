import request from '@/services/request'

// 乱码登录
export function DdLogin(data) {
    return request({
        url: '/user/ddlogin',
        method: 'post',
        data
    })
}

// 输入账号密码登录
export function login(data) {
    return request({
        url: '/user/login',
        method: 'post',
        data
    })
}

// 差标
export function GetStandards(data) {
    return request({
        url: '/user/getStandards',
        method: 'post',
        data
    })
}

// 差标
export function GetApplyTravelInfo(data) {
    return request({
        url: '/user/getApplyTravelInfo',
        method: 'post',
        data
    })
}