/* 历史记录页面相关方法 */
/* 生命周期：不随任一组件销毁而销毁 */
import { getTrainHistory } from "@/api/train"
import { getFlightOrders } from "@/api/aircraft"
import { getMyHotelOrders } from "@/api/hotel"
import moment from "moment";

// 服务器返回列表的原型
const listModel = {
    totalCount: 0,
    currentOrders: []
}

/**
 * 存储合并后的list的对象（单例模式）
 */
class PlaneList {
    _list = [] // 列表容器

    constructor(train = listModel, aircraft = listModel, hotel = listModel, car = listModel) {
        if (!PlaneList.instance) {
            PlaneList.instance = this;
        }
        let that = PlaneList.instance
        that.merge(train, aircraft, hotel, car)
        return that;
    }

    /**
     * 将新的数组和原有数组合并依照时间排序
     * @param {*} trainList... 
     */
    merge(trainList, aircraftList, hotelList, carList) {
        this.trainTotal = trainList.totalCount
        this.aircraftTotal = aircraftList.totalCount
        this.hotelTotal = hotelList.totalCount
        this.carTotal = carList.totalCount
        this.list = this._list.concat(trainList.currentOrders).concat(aircraftList.currentOrders).concat(hotelList.currentOrders).concat(carList.currentOrders)
    }

    // list的set函数。 参数为：空数组重置列表，非空数组合并列表
    set list(arr = []) {
        if (Object.prototype.toString.call(arr) !== '[object Array]') throw new Error("设置列表的参数必须是Array!");
        if (0 in arr) {
            this._list.concat(arr)
            arr.sort((l, r) => {
                let left = parseInt(moment(l.createTime).format("YYYYMMDD"))
                let right = parseInt(moment(r.createTime).format("YYYYMMDD"))
                return right - left
            })
        }
        this._list = arr;
        return 
    }

    // list的get函数，依照时间日期（天）分组，返回二维数组
    get list() {
        let allList = [], tempArr = [], tempTime
        tempTime = moment(this._list[0].createTime).format("YYYY-MM-DD")
        for (let i = 0; i < this._list.length; i++) {
            let curtime = moment(this._list[i].createTime).format("YYYY-MM-DD")
            if (curtime == tempTime) {
                tempArr.push(this._list[i])
            }
            else {
                allList.push(tempArr)
                tempArr = [this._list[i]]
                tempTime = curtime
            }
        }
        allList.push(tempArr)
        return allList
    }

    // 重置列表
    resetList() {
        this.trainTotal = 0
        this.aircraftTotal = 0
        this.hotelTotal = 0
        this.carTotal = 0
        this._list = []
    }

    // 获取总页数
    getTotalPage() {
        return this.trainTotal + this.aircraftTotal + this.hotelTotal + this.carTotal
    }
}

/**
 * 刷新列表，清空缓存数组
 */
export function HistoryReset() {
    let plane = new PlaneList()
    plane.resetList()
}


/**
 * 返回合并后的四个历史订单的集合(中枢)
 * @param {String} UserCode 
 * @param {Nunber} page 
 * @param {Number} pagesize 
 * @param {Object} query 查询条件
 */
export async function FetchHistoryList(UserCode, page = 1, pagesize = 20, query = {}) {
    let train = await fetchTrainHistory(UserCode.toString(), page, pagesize)
    let aircraft = await fetchAircraftHistory(UserCode.toString(), page, pagesize)
    let hotel = await fetchHotelHistory(UserCode.toString(), page, pagesize)
    let listObj = new PlaneList(train, aircraft, hotel)
    return {
        list: filterList(listObj.list, query),
        finish: page * pagesize >= listObj.getTotalPage()
    }
}

/**
 * 筛选列表，节省ajax成本
 * @param {Object} query 查询条件 
 */
export function FilterList(query = {}) {
    let listObj = new PlaneList()
    return filterList(listObj.list, query)
}

/**
 * 根据筛选条件过滤列表
 * 所有查询筛选方式不可能完全一样，因此没有循环查询条件,新的查询条件需要手动添加
 * @param {PlaneList.list} list 类PlaneList的list
 * @param {Object} query 查询条件，全部选传空
 * @returns {PlaneList.list} 返回格式不变
 */
function filterList(list, query = {}) {
    let { type, status, names } = query;
    let result = []
    list = list.forEach(element => {
        let arr = []
        element.forEach((item) => {
            let f = true
            if (type && item.hasOwnProperty('type') && item.type != type) f = false
            let itemStatus = item.status || item.orderStatusText
            if (status && !itemStatus.includes(status)) f = false
            if (names && !item.toStation.includes(names) && !item.passenger.includes(names) ) f = false
            if (f) arr.push(item)
        })
        if (0 in arr) result.push(arr)
    });
    return result;
}

/**
 * 获取火车票历史记录列表
 * @param {String} UserCode 
 * @param {Nunber} page 
 * @param {Number} pagesize 
 */
async function fetchTrainHistory(UserCode, page, pagesize) {
    return new Promise((resolve, reject) => {
        getTrainHistory({
            UserCode: UserCode,
            CurrentPage: page,
            PageSize: pagesize,
        }).then(response => {
            if (response.currentOrders)
                response.currentOrders.forEach(element => {
                    element.type = 'train'
                });
            else response.currentOrders = []
            resolve(response)
        }).catch(() => {
            reject([])
        })
    })
}

/**
 * 获取飞机票历史记录列表
 * @param {String} UserCode 
 * @param {Nunber} page 
 * @param {Number} pagesize 
 */
async function fetchAircraftHistory(UserCode, page, pagesize) {
    return new Promise((resolve, reject) => {
        getFlightOrders({
            UserCode: UserCode,
            CurrentPage: page,
            PageSize: pagesize,
        }).then(response => {
            if (response.currentOrders)
                response.currentOrders.forEach(element => {
                    element.type = 'aircraft'
                });
            else response.currentOrders = []
            resolve(response)
        }).catch(() => {
            reject([])
        })
    })
}

/**
 * 获取飞机票历史记录列表
 * @param {String} UserCode 
 * @param {Nunber} page 
 * @param {Number} pagesize 
 */
async function fetchHotelHistory(UserCode, page, pagesize) {
    return new Promise((resolve, reject) => {
        getMyHotelOrders({
            UserCode: UserCode,
            CurrentPage: page,
            PageSize: pagesize,
        }).then(response => {
            if (response.currentOrders)
                response.currentOrders.forEach(element => {
                    element.type = 'hotel'
                });
            else response.currentOrders = []
            resolve(response)
        }).catch(() => {
            reject([])
        })
    })
}