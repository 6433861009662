// 酒店相关功能
import {
    HotelRed,
    HotelOrange,
    HotelBlue
} from "@/common/config";
import { getHotelList } from "@/api/hotel";
import { HotelServiceProvider } from "@/common/config"


// 下方根据经纬度计算距离（地球是个球）
const EARTH_RADIUS = 6378137.0;    //单位M
const PI = Math.PI;

function getRad(d) {
    return d * PI / 180.0;
}

// 获得两地经纬度距离
export function getGreatCircleDistance(lat1, lng1, lat2, lng2) {
    let radLat1 = getRad(lat1);
    let radLat2 = getRad(lat2);

    let a = radLat1 - radLat2;
    let b = getRad(lng1) - getRad(lng2);

    let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s = s * EARTH_RADIUS;
    s = Math.round(s * 10000) / 10000.0;

    return s;
}


/**
 * 生成一个选房配置，不同性别分居
 * @param {number} capacity 房间容量
 * @param {Array} personList 人员信息
 * @returns {Object}
 *      personList: 人员列表，新增了roomNo属性
 *      total： 房间数
 *      roomPeo: select中人员标签所使用的数据 
 */
export function Recommend(capacity = 1, personList = []) {
    // 男人数量   女人数量  性别不详数量  男人房号     女人房号        相别不详房号   
    let man = 0, woman = 0, other = 0, manRoom = 0, womanRoom = 0, otherRoom = 0;
    let roomPeo = { 1: [], 2: [], 3: [], 4: [], 5: [] } // 订房数量不能超过5个 所以省点事。。
    personList.forEach(element => {
        if (element.gender == "男") {
            manRoom = Math.ceil(++man / capacity)
            manRoom = manRoom <= womanRoom ? womanRoom + 1 : manRoom
            manRoom = manRoom <= otherRoom ? otherRoom + 1 : manRoom
            element.roomNo = manRoom > 5 ? 0 : manRoom
            if (manRoom < 6) roomPeo[manRoom].push(element.idCard)
        }
        else if (element.gender == "女") {
            womanRoom = Math.ceil(++woman / capacity)
            womanRoom = (womanRoom <= manRoom ? manRoom + 1 : womanRoom)
            womanRoom = (womanRoom <= otherRoom ? otherRoom + 1 : womanRoom)
            element.roomNo = womanRoom > 5 ? 0 : womanRoom
            if (womanRoom < 6) roomPeo[womanRoom].push(element.idCard)
        }
        else {
            other++;
            otherRoom = manRoom + womanRoom + 1
            element.roomNo = otherRoom > 5 ? 0 : otherRoom
            if (otherRoom < 6) roomPeo[otherRoom].push(element.idCard)
        }
    });
    let totalRoom = Math.max(manRoom, womanRoom, otherRoom)
    return {
        personList,
        total: totalRoom > 5 ? 5 : totalRoom,
        roomPeo: roomPeo
    }
}

/**
 * 获取经纬度  注意！受限于百度api形式，该函数异步。后续操作使用回调函数完成。少用
 * @returns {
 *      longitude: float 经度,
        latitude: float 维度
 * }
 */
export function getPosition(cb = () => { }) {
    let geoLocation = new BMap.Geolocation();
    return geoLocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
            cb({
                longitude: r.point.lng,
                latitude: r.point.lat
            })
        }
        else {
            return {
                longitude: -1,
                latitude: -1
            }
        }
    });
}

/**
 * 搜索地址  注意！受限于百度api形式，该函数异步。后续操作使用回调函数完成。少用
 * @param {String} key // 关键字
 * @param {function} city // 城市
 * @param {function} cb() // 回调，参数为 经纬度
 */
export function searchPosition(key = "", city = "北京", cb = () => { }) {
    key = key || city //没有关键字就搜城市
    city = city || key
    var local = new BMap.LocalSearch(city, {
        onSearchComplete: function (results) {
            if (local.getStatus() == BMAP_STATUS_SUCCESS) {
                let point = results.Hr[0].point  // {lat:number,lng:number}
                cb(point, results)
            }
        }
    });
    local.search(key);
}

/**
 * 获取状态颜色。（对应到button的type字段，例如红色返回danger）
 * @param {Sting} text 
 */
export function getStatusColor(text = '占座中') {
    if (HotelRed.includes(text)) return "danger";
    if (HotelOrange.includes(text)) return "warning";
    if (HotelBlue.includes(text)) return "info";
    return "default";
}

/**
 * 列表排序  PA价格升序 PD价格降序 DA距离升序
 * @param {Array} list ajax获得的数据 
 * @param {Array} type 排序方式 
 */
export function sortList(list, type, ids = []) {
    let corps = ids.filter(e => HotelServiceProvider.includes(e))
    let arr = list;
    if (0 in corps)
        arr = arr.filter(e => {
            return ids.includes(e.corp)
        });
    switch (type.sort) {
        case 'PD':
            arr.sort((a, b) => {
                return b.price - a.price
            })
            break;
        case 'PA':
            arr.sort((a, b) => {
                return a.price - b.price
            })
            break;
        case 'DA':
            arr.sort((a, b) => {
                return a.distance - b.distance
            })
            break;
    }
    return arr;
}


/**
 * ----------- 下方酒店列表的实现类 ----------- 
 */
class htoelList {
    _list = []
    _refresh = 0 // 刷新量
    _supplier = [] // 已获取到的供应商
    _total = 0  // 已有供应商的总条目
    check = 0
    get list() { return this._list }    // 提供给外部的列表
    get total() { return this._total }  // 提供给外部总条数
    get supplier() { return this._supplier }  // 提供给外部当前供应商数量

    // 往列表中追加数据，不同刷新量不会追加
    setList(newData, refresh, jsonString) {
        if (this._refresh == refresh) {
            let req = JSON.parse(jsonString)
            this._list.push(...newData);
            this.check++;
            this._total += (0 in newData) ? newData[0].total : 0
            this._supplier.push(req.corp)
        }
    }

    // 刷新列表。清空且刷新量++
    resetList() {
        this._total = 0
        this._refresh++
        this._supplier = []
        this._list = []
    }

    // 更新数据，分发ajax
    update(query, ids) {
        let city = '' // 市区，依据“：”做为判断
        ids.forEach(a => {
            if (a.includes(":")) city = a.split(':')[0]
        })
        searchPosition(query.key, query.city+" "+city, position => { // 获取新的经纬度
            console.log(`${query.city} ${city} 坐标：(${position.lng},${position.lat})`)
            this._supplier = []
            ids = ids.filter(e => {
                return HotelServiceProvider.includes(e)
            })
            HotelServiceProvider.forEach(async (corp) => {
                const refreshf = this._refresh // 记录一下key
                query.latitude = position.lat
                query.longitude = position.lng
                query.corp = corp
                let req = JSON.stringify(query)
                if (0 in ids && !ids.includes(corp)) return this.setList([], refreshf, req)
                let result = await fetchHotelList(req, position)
                this.setList(result, refreshf, req)
            })
        })
    }

    // 列表排序，ajax完成后手动调用下
    sort(type, ids) {
        this._list = sortList(this._list, type, ids)
    }
}

// 获得实例
export function init() { return new htoelList }

// 获取酒店列表ajax接口
function fetchHotelList(query) {
    return new Promise(function (resolve, reject) {
        let req = JSON.parse(query)
        getHotelList(req).then(response => {
            let list = OrganizeHotelList(response)
            resolve(list)
        }).catch(error => {
            resolve([])
        })
    })
}

// 整理酒店列表
function OrganizeHotelList(list = []) {
    let arr = []
    list.forEach(first => {
        arr.push(...first.data)
    });
    return arr
}