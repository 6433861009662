// 行程单从这个城市出发
export const TravelFrom = "天津"


// 第三方供应商配置：["大唐", "差旅壹号", "行旅", "差旅管家"]
export const TrainServiceProvider = ["大唐"] // 火车包含的供应商
export const AircraftServiceProvider = ["大唐"] // 飞机包含的供应商
export const HotelServiceProvider = ["大唐", "行旅"] // 酒店包含的供应商


/* 机票 */
export const ServicePrice = 0 // 服务费，0元不会显示在页面


/* 超标缘由-默认第一个 */
export const REASONS = ["陪同出行", "商务洽谈", "其他情况"]


/* 保险相关 */
export const NeedAircraftInsurance = false // 如果买机票时想买保险设置true


/* 我的-提交反馈 & 反馈历史 */
export const NeedFeedback = false // 开启相关功能 
export const FeedbackUrl = "http://192.168.120.213:8082/#/" // 反馈系统地址
export const FeedbackSchool = "school=szht" // url参数中的学校标识符


/* Logo组件配置 */
export const LogoImg = [
    { name: '大唐', img: '/logo/dt.png' },
    { name: '同程', img: '/logo/tc.png' },
    { name: '途牛', img: '/logo/tn.png' },
    { name: '行旅', img: '/logo/auvgo.png' },
    { name: '差旅壹号', img: '/logo/yihao.png' },
    { name: '差旅管家', img: '/logo/guanjia.png' },
]


/* 酒店--入住日期时间 */
export const TimePickList = ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '23:59', '次日1点', '次日2点', '次日3点', '次日4点', '次日5点', '次日6点']


/* 酒店订单状态-----（不同供应商状态名不同） */

// 订单状态跟下方数组某项匹配时 状态文字变“红”
export const HotelRed = ['已退房', '已取消', '退房中', '未入住', '无效']

// 订单状态跟下方数组某项匹配时 状态文字变”黄“
export const HotelOrange = ['待提交', '确认中', '待入住', '待确认', '等待确认', '等待支付']

// 订单状态跟下方数组某项匹配时 状态文字变“蓝”
export const HotelBlue = ['已确认', '已入住']

// 订单状态跟下方数组某项匹配时 会展示“退房”按钮
export const HotelRefundBtn = ['待提交', '待支付', '已确认', '待确认', '待入住', '等待确认', '等待支付']

// 订单状态跟下方数组某项匹配时 会展示“确认”按钮
export const HotelConfirmBtn = ['待提交', '待支付']


/* 火车 */

/* 火车订单状态 */

// 订单状态跟下方数组某项匹配时 状态文字变”红“
export const TrainOrderRed = ['审批不通过', '购票失败', '出票失败', '抢票失败']

// 订单状态跟下方数组某项匹配时 状态文字变“黄”
export const TrainOrderOrange = ['抢票中', '等待支付', '待支付', '审批中', '待提交', '占座中']

// 订单状态跟下方数组某项匹配时 状态文字变”蓝“
export const TrainOrderBlue = ['部分改签', '全员改签', '出票成功', '已申请出票', '已付款', '部分退票']

// 订单状态跟下方数组某项匹配时 会展示“提交，取消”按钮
export const TrainOrderConfirmBtn = ['待提交']

/* 火车票状态 */

// 订单状态跟下方数组某项匹配时 状态文字变”红“
export const TrainTicketRed = ['退票失败', '出票失败']

// 订单状态跟下方数组某项匹配时 状态文字变“黄”
export const TrainTicketOrange = ['未出票', '改签中', '出票中', '待提交']

// 订单状态跟下方数组某项匹配时 状态文字变”蓝“
export const TrainTicketBlue = ['已出票', '已改签', '出票成功']

// 订单状态跟下方数组某项匹配时 会展示“退票，改签”按钮
export const TrainTicketRefundBtn = ['已出票', '出票成功', 'F']

// 订单状态跟下方数组某项匹配时 会展示“查看改签”按钮
export const TrainTicketChangeBtn = ['改签中', '改签中']


/* 飞机订单状态 */

// 订单状态跟下方数组某项匹配时 状态文字变”红“
export const AircraftRed = ['退票失败', '出票失败', '退票审批未通过', '改签失败', '改签审批未通过']

// 订单状态跟下方数组某项匹配时 状态文字变“黄”
export const AircraftOrange = ['待审批', '出票中', '待付款', '待提交', '占座中', '退票待审批', '退票待确认', '退票中', '改签待审批', '等待服务商确认', '改签中']

// 订单状态跟下方数组某项匹配时 状态文字变”蓝“
export const AircraftBlue = ['已出票', '已改签', '部分改签', '部分退票']

// 订单状态跟下方数组某项匹配时 会展示“出票，取消”按钮
export const AircraftConfirmBtn = ['待提交', '待付款']

// 订单状态跟下方数组某项匹配时 会展示“退票，改签”按钮
export const AircraftRefundBtn = ['已出票', '部分改签', '部分退票', '改签成功']


// 各个服务商电话
export function fetchPhone(corp = "大唐") {
    switch (corp) {
        case '大唐':
            return '4008225060'
        case '差旅一号':
        case '差旅壹号':
            return '4000517092'
        case '行旅':
            return '4006060011'
        default:
            return null
    }
}