import util from "../common/util";
import CHINA_HOLIDAY from "../common/节假日";

let logic = {};


//设定页面title
window.setTitle = function (title) {
    title = title ? title : '神州浩天商旅平台';
    window.document.title = title;
};

//页面跳转加密
logic.queryPathEncrypt = function (queryJson) {
    let queryString = JSON.stringify(queryJson);
    //加密
    //TODO
    return {code: queryString}
};

//页面跳转解密
logic.queryPathDecrypt = function (queryObj) {
    if (queryObj.hasOwnProperty('code')) {
        let queryString = queryObj.code;
        //解密
        //TODO
        let queryJson = JSON.parse(queryString);
        return queryJson
    } else {
        return null
    }

};


//填充日历，节假日 和 30天内 价格
logic.calendarFormat = function calendarFormatter(day) {
    const day_str = util.dateFtt('MM-dd', day.date);
    if (CHINA_HOLIDAY.holiday.hasOwnProperty(day_str)) {
        day.topInfo = CHINA_HOLIDAY.holiday[day_str].name;
    }
    //三十天内价格可以请求一个每天晚上缓存的价格
    // day.bottomInfo='120.0'
    day.className = 'calendar_top_info_szht';
    return day
};

//设定检索履历
logic.setSearchHistory = function (key, value) {
    let storage = window.localStorage;
    let h = storage.getItem(key);
    let l;
    if (h === undefined || h == null) {
        l = [];
    } else {
        l = JSON.parse(h);
    }
    l.forEach((v, i) => {
        if (v === value) {
            l.splice(i, 1)
        }
    });
    l.unshift(value);
    if (l.length > 5) {
        l.pop()
    }
    storage.setItem(key, JSON.stringify(l));
};

//获取检索履历
logic.getSearchHistory = function (key) {
    let storage = window.localStorage;
    let h = storage.getItem(key);
    if (h === undefined || h == null) {
        return [];
    } else {
        return JSON.parse(h);
    }
};


export default logic;