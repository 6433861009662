<template>
  <van-row>
    <van-image class="pic_margin" width="100vw" :src="img_travel" />

    <!-- <van-row type="flex" justify="center"> -->
      <van-row class="pic-next-cont">
        <!--购票功能区域 start-->
        <van-row class="itembk">
          <van-row style="padding: 10px">
            <van-notice-bar left-icon="volume-o" color="#2A79FF" background="#ffffff">神州浩天商旅平台提供高校差旅报销一体化解决方案</van-notice-bar>
            <div style="height: 1px;width: 90vw;background-color: #f1f1f1;margin-bottom:5px"></div>
            <div class="box">
              <div class="hezi">
                <div class="shang" @click="$router.push('/buyAircraft')">
                  <p>
                    <button class="button" style="background-image: linear-gradient(to right, white ,white,#cff5fe);">飞机票</button>
                    <van-icon class="iconfont" color="#319afb" class-prefix="icon" name="jichangxiao" />
                  </p>

                  <h6>特价机票 随心出行</h6>
                </div>
                <div class="shang" @click="$router.push('/buyHotel')">
                  <p>
                    <button class="button" style="background-image: linear-gradient(to right, white ,white,#fed7da);">享酒店</button>
                    <van-icon class="iconfont" color="#f55c5f" class-prefix="icon" name="jiudian" />
                  </p>
                  <h6>商旅尊享 雅致安心</h6>
                </div>
              </div>
              <div class="hezi">
                <div class="shang" style="width:100%" @click="$router.push('/buyTrain')">
                  <p>
                    <button class="button" style="background-image: linear-gradient(to right, white ,white,#bfffec);">火车票</button>
                    <van-icon class="iconfont" color="#2acac8" class-prefix="icon" name="huochepiaomianxing" />
                  </p>
                  <h6>闪电出票 畅快预订</h6>
                </div>
                <!-- <div class="shang" @click="$router.push('/buyCar')">
                  <p>
                    <button
                      class="button"
                      style="background-image: linear-gradient(to right, white ,white,#ffe8b1);"
                    >享专车</button>
                    <van-icon
                      class="iconfont"
                      color="#ffb400"
                      class-prefix="icon"
                      name="chuzu"
                      style="justify-content: flex-end;"
                    />
                  </p>
                  <h6>专车专享 舒适便捷</h6>
                </div>-->
                <!-- <div class="shang" @click="$router.push('/Polymerization')">
                  <p>
                    <button class="button" style="background-image: linear-gradient(to right, white ,white,#ffe8b1);">聚合订</button>
                    <van-icon class="iconfont" color="#ffb400" class-prefix="icon" name="jihuozhongzhuan" style="justify-content: flex-end;" />
                  </p>
                  <h6>聚合订票 便捷出行</h6>
                </div> -->
              </div>
            </div>
          </van-row>
        </van-row>

        <div class="gap"></div>

        <!--出行人选择区域 start-->
        <van-row class="itembk">
          <van-panel>
            <template #header>
              <van-row type="flex" justify="space-between" style="padding:10px 16px;border-bottom: 1px solid #ebedf0;">
                <van-col style="font-size: 14px;color: #323233;">人员信息</van-col>
                <van-col>
                  <van-row @click="allCollapse" type="flex" justify="center" align="center" style="font-size: 10px;color: #999999">折叠</van-row>
                </van-col>
              </van-row>
            </template>
            <van-row style="padding: 10px;text-align: start">
              <van-collapse :border="false" v-model="collapseItems">
                <template>
                  <van-collapse-item v-for="(item,index) in personList" :key="index" :title="item.username+'（'+item.usercode+'）'" :name="item.tel" value="差标" value-class="collapse_value" class="auto-cell">
                    <van-row class="information">
                      <van-row v-if="item.birthday">出生日期：{{item.birthday}}</van-row>
                      <van-row v-if="item.gender">
                        <span class="gender">性别</span>：
                        <span>{{item.gender}}</span>
                      </van-row>
                      <van-row v-if="item.idCard">
                        <span class="gender">证件号</span>：
                        <span>{{item.idCard}}</span>
                      </van-row>
                      <van-row v-if="item.tel">
                        <span class="gender">电话</span>：
                        <span>{{item.tel}}</span>
                      </van-row>
                      <van-row v-if="item.stand.allowBookHighest">飞机等级：{{item.stand.allowBookHighest|aircraftLevel}}</van-row>
                      <van-row v-if="item.stand.trainSeatInfo_seatInfo_text">火车标准：{{item.stand.trainSeatInfo_seatInfo_text}}</van-row>
                      <van-row v-if="item.stand.hotelMaxPrice">住宿标准：{{item.stand.hotelMaxPrice}}</van-row>
                    </van-row>
                  </van-collapse-item>
                </template>
              </van-collapse>
            </van-row>
          </van-panel>
        </van-row>

        <div class="gap"></div>

        <!--行程单展示区域 start-->
        <BodyList class="itembk"/>

        <div class="gap"></div>

        <!--底部垫底用，对付Tabbar-->
        <van-row type="flex" style="height: 5rem;font-size: 12px;color: #bfbfbf;" justify="center">
          <van-col>2020 © 天津神州浩天科技有限公司. All Rights Reserved.</van-col>
        </van-row>
      </van-row>
    <!-- </van-row> -->
  </van-row>
</template>

<script>
import { mapGetters } from "vuex";
import BodyList from "@/components/InfoList";

export default {
  name: "Travel",
  components: { BodyList },
  computed: {
    ...mapGetters(["personList", "travelInfo"])
  },
  data() {
    return {
      img_travel: require("../assets/travel.jpg"),
      collapseItems: [] //记录折叠
    };
  },
  methods: {
    allCollapse() {
      if (this.collapseItems.length === 0) {
        let list = [];
        this.personList.forEach((val, ind) => {
          list.push(val.tel);
        });
        this.collapseItems = list;
      } else {
        this.collapseItems = [];
      }
    }
  },
  filters:{
    aircraftLevel(data){
      switch(data){
        case 4:
          return "最高可订 <头等舱>";
        case 3:
          return "最高可订 <公务舱>";
        case 2:
          return "最高可订 <超值经济舱>";
        case 1:
          return "最高可订 <经济舱>";
      }
    }
  }
};
</script>

<style lang="less" scoped>
.collapse_value span {
  font-size: 10px;
}
.service {
  color: #2396f1;
}
.box {
  width: 100%;
  height: 100%;
}
.hezi {
  width: 100%;
  height: 80px;
  justify-content: space-between;
  gap: 0.6rem;
  display: flex;
}
p {
  position: relative;
  width: 90%;
  z-index: 2;
  line-height: 8px;
  margin: 11px auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.shang {
  position: relative;
  width: 49%;
  height: 90%;
  background-color: white;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
h6 {
  margin: 6px auto;
  position: relative;
  right: -7px;
  color: #888888;
}
.button {
  position: relative;
  z-index: 1;
  top: -2px;
  right: 10px;
  border: none;
  border-radius: 20px;
  padding: 3px 15px;
  margin: 3px 10px;
}
.iconfont {
  font-size: 24px;
}

.gender {
  width: 55px;
  display: inline-block;
  text-align: justify;
  text-align-last: justify;
}
</style>