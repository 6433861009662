/* 用户相关的方法 */
import { DdLogin, GetStandards } from "@/api/user.js";
import { getHotelStandard } from "@/api/hotel";
import store from '@/store'


/**
 * 检查座位是否合规
 * @param {number} userRole 等级
 * @param {string} seatType 座位类型，会拆分（取[0]
 * @returns {Boolean}
 */
export function checkSeat(userRole, seatType = '二等座', type = "train") {
    let stand = userRole.stand
    switch (type) {
        case "train":
            seatType = seatType.split("(")[0].trim()
            return stand && stand.trainSeatInfo_seatInfo_text.includes(seatType)
        case "aircraft":
            return stand && stand.allowBookHighest >= seatType.seatGrade
    }
}

/**
 * 整理超标数据
 * @param {Array} list 人员列表
 * @param {String} seatInfo 座位
 * @param {String} explanation 原因
 * @param {String} explanation1 备注
 */
export function CreateTrainPeoList(list = [], seatInfo, explanation, explanation1, type = "train") {
    list.forEach(element => {
        let ok = checkSeat(element, seatInfo, type)
        element.isViolation = !ok;
        // 如果超标
        if (!ok) {
            switch (type) {
                case 'train':
                    element.violationContent = `${element.username}超标（合规席位：${element.stand.trainSeatInfo_seatInfo_text}）`
                    break;
                case 'aircraft':
                    element.violationContent = `${element.username}超标（合规等级：${element.stand.allowBookHighest}）`
            }
            element.violationReasonChinese = explanation
            element.violationReasonRemark = explanation1
        }
        else {
            delete element.violationContent
            delete element.violationReasonChinese
            delete element.violationReasonRemark
        }
    });
    return list
}

// 登录 顺便处理下差标
export function LoginFun(param = document.location.href.split("?param=")[1]) {
    return new Promise(function (resolve, reject) {
        DdLogin({ param: param }).then(response => {
            let res = JSON.parse(response);
            res.loginUser.isTravel = false
            let travelUsers = res.travelUsers
            travelUsers = travelUsers.map((e) => {
                if (typeof e.isTravel != "boolean")
                    e.isTravel = e.isTravel == "true" ? true : false
                return e
            })
            GetStandards().then(standard => {

                getHotelStandard({
                    checkIn: new Date(),
                    checkOut: new Date(),
                    travelUsers: travelUsers
                })
                    .then(hotelStand => {
                        let peoArr = []
                        // 循环人
                        travelUsers.forEach(peo => {
                            peo.stand = {};
                            // 循坏差标
                            standard.forEach(stand => {
                                // 差标id等于人的差标等级id丢进去
                                if (peo.travelLevelId == stand.postBandId) {
                                    stand.trainSeatInfo_seatInfo_text = stand.trainSeatInfo_seatInfo_text.replace(/\s+/g, "")
                                    peo.stand = stand;
                                }
                            });
                            hotelStand.forEach(hotelS => {
                                // 差标id等于人的差标等级id丢进去
                                if (peo.usercode == hotelS.userId) {
                                    peo.hotelStand = hotelS.hotelMaxPrice;
                                }
                            });
                            peoArr.push(peo)
                        });
                        store.dispatch("project/setTravelInfo", res);
                        store.dispatch("project/setPersonList", peoArr);
                        resolve(true);
                    })
                    .catch(err => {
                        reject(false);
                    });
                // resolve(true)
            })
                .catch(error => {
                    reject(false)
                })
        }).catch(error => {
            reject(false)
        })
    })
}