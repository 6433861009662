/* 机票相关方法 */
import CHINA_AIRCRAFT_LIST from "../common/机场list";
import { flightOrderReload, createFlightOrder, applyFlightOrderChange } from "@/api/aircraft";
import router from '@/router'
import {
    AircraftRed,
    AircraftOrange,
    AircraftBlue
} from "@/common/config";

/**
 * 检查是否有指定城市(include匹配)
 * @param {String} city 城市名 
 * @returns {Object} 返回匹配的对象，没有匹配到返回{}
 */
export function checkCity(city) {
    let result = []
    for (let key in CHINA_AIRCRAFT_LIST) {
        result = result.concat(CHINA_AIRCRAFT_LIST[key])
    }
    result = result.filter(element => {
        return element.name.includes(city)
    })
    result.push({})
    return result[0]
}

/* 获取柱状图数据原型 */
function getTemple() {
    return [
        {
            time: "06:00"
        },
        {
            sales: 0,
            time: "06:30"
        },
        {
            time: "09:00"
        },
        {
            sales: 0,
            time: "09:30"
        },
        {
            time: "12:00"
        },
        {
            sales: 0,
            time: "12:30"
        },
        {
            time: "15:00"
        },
        {
            sales: 0,
            time: "15:30"
        },
        {
            time: "18:00"
        },
        {
            sales: 0,
            time: "18:30"
        },
        {
            time: "21:00"
        },
        {
            sales: 0,
            time: "21:30"
        },
        {
            time: "24:00"
        }
    ]
}

/**
 * 返回柱状图数据
 * @param {Array} sourceData 
 */
export function SortForChart(sourceData = []) {
    let arr = getTemple()
    sourceData.forEach(element => {
        let range = parseInt(element.departTime.split(':')[0])
        let index = Math.floor((range - 6) / 3) * 2 + 1
        if (index >= 0) {
            let price = parseInt(element.canBuyMinPrice || 0)
            if (arr[index].sales < 1) arr[index].sales = price
            if (price > 0 && arr[index].sales > price) arr[index].sales = price
        }
    });
    return arr;
}

/**
 * 返回指定时间段的柱状图数据
 * @param {Array} sourceData 
 * @param {Date} time 
 */
export function FliterByChart(sourceData = [], time) {
    let arr = []
    let cur = Math.floor((parseInt(time.split(':')[0]) - 6) / 3)
    sourceData.forEach(element => {
        let range = parseInt(element.departTime.split(':')[0])
        let index = Math.floor((range - 6) / 3)
        if (cur == index) arr.push(element)
    });
    return arr;
}

/**
 * 返回指定城市的code
 * @param {String} city 城市全称
 * @returns {String} cityCode 城市code
 */
export function FetchCityCode(city) {
    let code = ""
    for (let key in CHINA_AIRCRAFT_LIST) {
        CHINA_AIRCRAFT_LIST[key].forEach(element => {
            if (element.name == city) {
                code = element.code
            }
        });
        if (code != "") break;
    }
    return code
}


/**
 * 下单
 * @param {*} req 请求参数 
 * @param {*} name 当前路由的名称，转到其他页面结束死循环
 */
export async function createOrder(req = {}, name = "") {
    return new Promise(function (resolve, reject) {
        createFlightOrder(req)
            .then(async response => {
                let ticket1;
                while (1) {
                    if (router.history.current.name != name) return;
                    ticket1 = await refreshTicket(response);
                    if (ticket1) break;
                }
                resolve(ticket1)
            })
            .catch(err => {
                resolve(false)
            });
    })
}

/**
 * 改签下单
 * @param {*} req 请求参数 
 * @param {*} name 当前路由的名称，转到其他页面结束死循环
 */
export async function createChangeOrder(req = {}, name = "") {
    return new Promise(function (resolve, reject) {
        applyFlightOrderChange(req)
            .then(async response => {
                let ticket1;
                while (1) {
                    if (router.history.current.name != name) return resolve(false);
                    ticket1 = await refreshTicket(response);
                    if (ticket1) break;
                }
                resolve(ticket1)
            })
            .catch(err => {
                resolve(false)
            });
    })
}

/**
 * 查询占座是否成功
 * @param {Object} req
 * @returns {Object} 票据信息
 */
export async function refreshTicket(req = {}) {
    return new Promise(function (resolve, reject) {

        setTimeout(() => {
            flightOrderReload({
                corp: req.corp,
                outorderno: req.data.outOrderNo,
                orderno: req.data.orderSerialNo,
            }).then(response => {
                if (response.orderStatus.toString() != '4') {
                    resolve(response)
                }
                else resolve(false)
            });
        }, 10 * 1000);
    })
}

/**
 * 获取状态颜色。（对应到button的type字段，例如红色返回danger）
 * @param {Sting} text 
 */
export function getStatusColor(text = '占座中') {
    if (AircraftRed.includes(text)) return "danger";
    if (AircraftOrange.includes(text)) return "warning";
    if (AircraftBlue.includes(text)) return "info";
    return "default";
}

/**
 * 返回订单状态对应的文字信息和可操作的状态
 * @param {String} code 
 */
export function getOrderStatus(code = '') {
    switch (parseInt(code)) {
        case 0:
            return {
                name: '待审批',
                canpay: false, // 支付，取消按钮是否可用
                type: 'warning'
            }
        case 3:
            return {
                name: '待提交',
                canpay: true,
                type: 'warning'
            }
        case 4:
            return {
                name: '占座中',
                canpay: false,
                type: 'warning'
            }
        case 22:
            return {
                name: '出票失败',
                canpay: false,
                type: 'danger'
            }
        case 40:
            return {
                name: '退票待审批', // 退票
                canpay: false,
                type: 'warning'
            }
        case 41:
            return {
                name: '退票待确认', // 退票
                canpay: true,
                type: 'warning'
            }
        case 42:
            return {
                name: '退票中',
                canpay: false,
                type: 'warning'
            }
        case 47:
            return {
                name: '退票失败',
                canpay: false,
                type: 'danger'
            }
        case 48:
            return {
                name: '退票审批未通过',
                canpay: false,
                type: 'warning'
            }
        case 49:
            return {
                name: '取消退票',
                canpay: false,
                type: 'info'
            }
        case 60:
            return {
                name: '改签待审批',
                canpay: false,
                type: 'warning'
            }
        case 64:
            return {
                name: '部分改签',
                canpay: false,
                type: 'info'
            }
        case 66:
            return {
                name: '取消改签',
                canpay: false,
                type: 'info'
            }
        case 67:
            return {
                name: '改签失败',
                canpay: false,
                type: 'danger'
            }
        case 68:
            return {
                name: '改签审批未通过',
                canpay: false,
                type: 'warning'
            }
        case 92:
            return {
                name: '等待服务商确认',
                canpay: false,
                type: 'warning'
            }
        case 93:
            return {
                name: '改签中',
                canpay: false,
                type: 'warning'
            }
        case 94:
            return {
                name: '部分退票',
                canpay: false,
                type: 'info'
            }
        case 95:
            return {
                name: '退票成功',
                canpay: false,
                type: 'info'

            }
        case 96:
            return {
                name: '已取消',
                canpay: false,
                type: 'info'
            }
        case 97:
            return {
                name: '已出票',
                canpay: false,
                type: 'info'
            }
        case 98:
            return {
                name: '出票中',
                canpay: false,
                type: 'warning'
            }
        case 99:
            return {
                name: '待付款',
                canpay: true,
                type: 'warning'
            }
    }
}

/**
 * 翻译票状态
 */
export function getPassengerStatus(t) {
    switch (t) {
        case "0":
            return "未出票 "
        case "1":
            return "待付款"
        case "10":
            return "出票中"
        case "11":
            return "已出票"
        case "12":
            return "改签中"
        case "20":
        case "24":
            return "待审批"
        case "26":
            return "改签中"
        case "27":
            return "改签成功"
        case "28":
            return "改签失败"
        case "29":
            return "审批不通过"
        case "41":
        case "42":
        case "43":
        case "44":
            return "已取消"
        case "45":
            return "出票中"
        case "50":
            return "退票待审批"
        case "51":
            return "退票中"
        case "52":
            return "已退票"
        case "53":
            return "退票失败"
        case "61":
            return "已使用"
        default:
            return "其他"
    }
};

/**
 * 原生js修改票面数据
 * @param {Array} arr 原数组，取corp
 */
export function updataTicketDom(arr) {
    setTimeout(() => {
        document.getElementsByClassName("van-coupon__description").forEach(e => {
            if (e.innerHTML == "有超标情况") e.classList.add("red");
            else e.classList.remove("red")
        });
        document.getElementsByClassName("van-coupon__name").forEach((e, i) => {
            let f = true
            let corp = arr[i].corp
            e.children.forEach(element => {
                if (!f && corp == element.id)
                    f = false
                else e.removeChild(e.children[0]) // 删除之前logo
            });
            if (f) {
                let tempDom = document.getElementById(corp).cloneNode(true)
                tempDom.style.display = "inline-block"
                e.appendChild(tempDom)
            }
        });
    }, 500);
}