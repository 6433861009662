// ajax拦截器
import axios from 'axios'
import { init } from './cancelAxios.js' // 存放CancelToken得单例
import { Notification } from 'element-ui';
let CancelToken = axios.CancelToken
let cancelList = init() // 获得实例

// 白名单不抛错
const whiteList = ["/flight/getChangeFlightTicket", "/flight/getFlightTicket", "/train/getTrainTicket"]

// create an axios instance
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    baseURL: 'v1/',
    // timeout: 60 * 1000
})

// request interceptor
service.interceptors.request.use(
    config => {
        config.cancelToken = new CancelToken(function executor(c) {
            cancelList.push({ u: config.url + '&' + config.method, cancel: c })
        })
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
    */
    response => {
        const res = JSON.parse(response.data)
        // do something after received response
        if (res.hasOwnProperty('success')) {
            if (res.success) {
                return res.data
            } else {
                // 如果不在白名单 弹出提示框（在路由拦截器中关闭）
                if (!whiteList.includes(response.config.url))
                    Notification.error({
                        customClass: 'diyNotification',
                        title: '错误',
                        duration: 0,
                        offset: 24,
                        message: res.msg || "响应错误，请联系管理员"
                    });
                return Promise.reject(new Error(res.msg || 'Error'))
            }
        }
        else return res
    },
    error => { // 请求出现了错误
        if(error.message) // 手动取消ajax没有错误信息，手动取消也不需要报错
            Notification.error({
                customClass: 'diyNotification',
                title: '错误',
                duration: 0,
                offset: 24,
                message: error.message
            });
        return Promise.reject(error)
    }
)

export default service