// 路由守卫，仅针对流程上出现的跳转做优化控制。
import router from './router'
import store from './store'
import { LoginFun } from "@/services/user.js";
import { ToTop } from "@/services/utils";
import { init } from '@/services/cancelAxios.js' // 存放CancelToken得单例
import { Notification } from 'element-ui';
import { Dialog } from 'vant';
let cancelList = init() // 获得实例

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
    // 取消之前页面全部ajax，关闭所有弹窗
    cancelList.list.forEach(element => {
        element.cancel()
    });
    Dialog.close()
    Notification.closeAll()

    const hasToken = store.getters.travelInfo
    const curparam = document.location.search.split('?param=')[1]
    if (curparam) {     // 如果有登录参数
        if (curparam != store.getters.loginParam) { // 如果登录参数不一样(登陆后应该永远一致，应该是search算在url里了)
            // 清空一些东西
            store.dispatch("project/cleanTravelInfo"); 
            store.dispatch("aircraft/cleanAircraftOrderInfo");
            store.dispatch("train/cleanOrderInfo");
            await LoginFun(curparam)       // 调用登录方法，该方法会检验是否为新的申请单
            store.dispatch("project/setLoginParam",curparam); // 记录参数
        }
    }
    if (to.path.includes("rder") && from.path.includes("ayment")) return next({ path: '/' }) // 订单详情页不允许跳转到下单界面
    if (!hasToken || JSON.stringify(hasToken) == "{}") {    // 如果用户没登陆
        if (whiteList.includes(to.path)) next();            // 如果请求地址在白名单中放行
        else next({ path: '/login' });                      // 否则转到登录页
    } else {
        if (to.path === '/login') {                         // 如果已经登陆禁止访问登录页
            next({ path: '/' })
        }
        else {
            next() // 一切正常放行
        }
    }
})




