
import request from '@/services/request'
// import axios from 'axios'
// let CancelToken = axios.CancelToken

// 获取车票列表
export function getTrainTicket(data) {
    // let cancel
    // setTimeout(function () {
    //     //只要我们去调用了这个cancel()方法，没有完成请求的接口便会停止请求
    //     cancel()
    //   }, 1000)
    return request({
        url: '/train/getTrainTicket',
        method: 'post',
        data,
        // cancelToken: new CancelToken(function executor(c) {
        //     cancel = c
        // })
    })
}

// 获取经停站信息
export function getTrainStopStation(data) {
    return request({
        url: '/train/getTrainStopStation',
        method: 'post',
        data
    })
}

// 占座
export function createTrainOrder(data) {
    return request({
        url: '/train/createTrainOrder',
        method: 'post',
        data
    })
}

// 刷新占座状态，订单详情
export function orderStatusReload(data) {
    return request({
        url: '/train/orderStatusReload',
        method: 'post',
        data
    })
}

// 确认出票
export function applyIssueOrder(data) {
    return request({
        url: '/train/applyIssueOrder',
        method: 'post',
        data
    })
}

// 取消出票
export function cancelOrder(data) {
    return request({
        url: '/train/cancelOrder',
        method: 'post',
        data
    })
}

// 获取火车票历史订单
export function getTrainHistory(data) {
    return request({
        url: '/train/getTrainOrders',
        method: 'post',
        data
    })
}

// 改签：下单
export function applyChangeOrder(data) {
    return request({
        url: '/train/applyChangeOrder',
        method: 'post',
        data
    })
}

// 改签：刷新占座状态，订单详情
export function changeOrderStatusReload(data) {
    return request({
        url: '/train/changeOrderStatusReload',
        method: 'post',
        data
    })
}

// 改签：确认出票
export function confirmChangeOrder(data) {
    return request({
        url: '/train/confirmChangeOrder',
        method: 'post',
        data
    })
}

// 改签：取消出票
export function cancelChangeTicket(data) {
    return request({
        url: '/train/cancelChangeTicket',
        method: 'post',
        data
    })
}

// 退票
export function applyRefundOrder(data) {
    return request({
        url: '/train/applyRefundOrder',
        method: 'post',
        data
    })
}
