<template>
  <div class="contain">
    <div class="part">
      <van-row type="flex" justify="space-between">
        <van-col>
          <van-tag plain color="#51f0ed" type="primary" class="icon-gap">
            <van-icon class="iconfont" class-prefix="icon" name="huochezhanxiao" />火车
          </van-tag>
          <Logo class="logo" :corp="data.corp" />
        </van-col>
        <van-col>
          <span :class="'status '+goColor(data.orderStatusText)">{{data.orderStatusText}}</span>
        </van-col>
      </van-row>
      <div class="item-cont">
        <p class="card-item" v-if="data.applyNo">申请单号：{{data.applyNo}}</p>
        <p class="card-item" v-if="data.applyReason">申请事由：{{data.applyReason}}</p>
        <p class="card-item" v-if="data.trainNo">乘坐车次：{{data.trainNo}}</p>
        <p class="card-item" v-if="data.fromStation">
          始末站点：{{data.fromStation}}
          <span class="icon iconfont icon-icon_jipiaodancheng"></span>
          {{data.toStation}}
        </p>
        <p class="card-item" v-if="data.planBeginDate">发车时间：{{data.planBeginDate}}</p>
        <p class="card-item" v-if="data.passenger">出行人员：{{data.passenger}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import { getStatusColor } from "@/services/train";
export default {
  name: "TrainItem",
  components: { Logo },
  props: {
    data: {
      default: 1
    }
  },
  data() {
    return {};
  },
  methods: {
    goColor(type) {
      return getStatusColor(type);
    }
  }
};
</script>

<style lang="less" src="./item.less" scoped></style>
<style lang="less" scoped>
</style>