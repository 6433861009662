import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Travel from '../views/Travel.vue'
import Plan from '../views/Plan/Plan.vue'
import Setting from '../views/Setting/Setting.vue'
import util from "../common/util";
import logic from "../services/commonLogic"

/**
 * 看一下 ！！看一下！！
 * 1、path遵守规则：  
 *      创建订单页面的路由：order....   订单详情页面的路由：payment....
 *      原因：在路由守卫中依据上方两个关键字做跳转控制，详情看permission.js。
 * 2、meta: { trans_index: 10 }
 *      trans_index为过渡动画的权重参数（左滑右滑）
 * 3、是否嵌套路由影响不大，纯属美观。所以不套了
 */

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '/',
        component: Home,
        children: [
            {
                path: '/',
                name: 'travel',
                component: Travel,
                meta: { trans_index: 10, keepAlive: true }  // trans_index: 控制跳转效果，比大小，小的返回大的前进
            },
            {
                path: '/plan',
                name: 'plan',
                component: Plan,
                meta: { trans_index: 11, keepAlive: true }
            },
            {
                path: '/setting',
                name: 'setting',
                component: Setting,
                meta: { trans_index: 12, keepAlive: true }
            },
        ]
    },
    {
        path: '/policy',
        name: 'policy',
        component: () => import('../views/Setting/Policy.vue'),
        meta: { trans_index: 13 }
    },
    {
        path: '/memorabilia',
        name: 'memorabilia',
        component: () => import('../views/Setting/Memorabilia.vue'),
        meta: { trans_index: 13 }
    },
    {
        path: '/Login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: { trans_index: 0 }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: { trans_index: 300 }
    },
    {
        path: '/selectCity', // 选择城市
        name: 'selectCity',
        component: () => import('../views/SelectCity.vue'),
        meta: { trans_index: 200 }
    },

    // 火车
    {
        path: '/buyTrain',
        name: 'buyTrain',
        component: () => import('@/views/Train/BuyTrain.vue'),
        meta: { trans_index: 20 }
    },
    {
        path: '/searchTrain',
        name: 'searchTrain',
        component: () => import('@/views/Train/SearchTrain.vue'),
        meta: { trans_index: 21, keepAlive: true }
    },
    {
        path: '/orderTrain',
        name: 'orderTrain',
        component: () => import('@/views/Train/OrderTrain.vue'),
        meta: { trans_index: 22, keepAlive: true }
    },
    {
        path: '/payment',
        name: 'payment',
        component: () => import('@/views/Train/Payment.vue'),
        meta: { trans_index: 101, keepAlive: true }
    },
    {
        path: '/buyTrainChange',
        name: 'buyTrainChange',
        component: () => import('@/views/Train/BuyTrain.vue'),
        meta: { trans_index: 20 }
    },
    {
        path: '/searchTrainChange',
        name: 'searchTrainChange',
        component: () => import('@/views/Train/SearchTrain.vue'),
        meta: { trans_index: 21 }
    },
    {
        path: '/orderTrainChange',
        name: 'orderTrainChange',
        component: () => import('@/views/Train/OrderChangeTrain.vue'),
        meta: { trans_index: 23, keepAlive: true }
    },
    {
        path: '/paymentChange',
        name: 'paymentChange',
        component: () => import('@/views/Train/PaymentChange.vue'),
        meta: { trans_index: 102, keepAlive: true }
    },

    // 酒店
    {
        path: '/buyHotel',
        name: 'buyHotel',
        component: () => import('@/views/Hotel/BuyHotel.vue'),
        meta: { trans_index: 31 }
    },
    {
        path: '/searchHotel',
        name: 'searchHotel',
        component: () => import('@/views/Hotel/SearchHotel.vue'),
        meta: { trans_index: 32, keepAlive: true }
    },
    {
        path: '/detailHotel',
        name: 'detailHotel',
        component: () => import('@/views/Hotel/DetailHotel.vue'),
        meta: { trans_index: 33, keepAlive: true }
    },
    {
        path: '/orderHotel',
        name: 'orderHotel',
        component: () => import('@/views/Hotel/OrderHotel.vue'),
        meta: { trans_index: 34, keepAlive: true }
    },
    {
        path: '/paymentHotel',
        name: 'paymentHotel',
        component: () => import('@/views/Hotel/PaymentHotel.vue'),
        meta: { trans_index: 35, keepAlive: true }
    },
    {
        path: '/paymentHotelRefund',
        name: 'paymentHotelRefund',
        component: () => import('@/views/Hotel/PaymentRefundHotel.vue'),
        meta: { trans_index: 36, keepAlive: true }
    },

    // 飞机
    {
        path: '/buyAircraft',
        name: 'aircraft',
        component: () => import('@/views/Aircraft/BuyAircraft.vue'),
        meta: { trans_index: 41 }
    },
    {
        path: '/searchAircraftTo',
        name: 'searchAircraftTo',
        component: () => import('@/views/Aircraft/SearchAircraftTo.vue'),
        meta: { trans_index: 42, keepAlive: true }
    },
    {
        path: '/searchAircraftBack',
        name: 'searchAircraftBack',
        component: () => import('@/views/Aircraft/SearchAircraftBack.vue'),
        meta: { trans_index: 43, keepAlive: true }
    },
    {
        path: '/searchAircraftSingle',
        name: 'searchAircraftSingle',
        component: () => import('@/views/Aircraft/SearchAircraftSingle.vue'),
        meta: { trans_index: 42, keepAlive: true }
    },
    {
        path: '/searchAircraftChange',
        name: 'searchAircraftChange',
        component: () => import('@/views/Aircraft/SearchAircraftChange.vue'),
        meta: { trans_index: 42, keepAlive: true }
    },
    // {
    //     path: '/searchReturnAircraft',
    //     name: 'searchReturnAircraft',
    //     component: () => import('@/views/Aircraft/SearchAircraft.vue'),
    //     meta: { trans_index: 42 }
    // },
    // {
    //     path: '/searchAircraftChange',
    //     name: 'searchAircraftChange',
    //     component: () => import('@/views/Aircraft/SearchAircraft.vue'),
    //     meta: { trans_index: 42, keepAlive: true }
    // },
    {
        path: '/orderAircraft',
        name: 'orderAircraft',
        component: () => import('@/views/Aircraft/OrderAircraft.vue'),
        meta: { trans_index: 43, keepAlive: true }
    },
    {
        path: '/orderAircraftChange',
        name: 'orderAircraftChange',
        component: () => import('@/views/Aircraft/OrderAircraftChange.vue'),
        meta: { trans_index: 43, keepAlive: true }
    },
    {
        path: '/orderReturnAircraft',
        name: 'orderReturnAircraft',
        component: () => import('@/views/Aircraft/OrderReturnAircraft.vue'),
        meta: { trans_index: 44, keepAlive: true }
    },
    {
        path: '/paymentAircraft',
        name: 'paymentAircraft',
        component: () => import('@/views/Aircraft/Payment.vue'),
        meta: { trans_index: 101, keepAlive: true }
    },
    {
        path: '/paymentAircraftRefund',
        name: 'paymentAircraftRefund',
        component: () => import('@/views/Aircraft/PaymentRefund.vue'),
        meta: { trans_index: 103, keepAlive: true }
    },
    {
        path: '/paymentAircraftChange',
        name: 'paymentAircraftChange',
        component: () => import('@/views/Aircraft/PaymentChange.vue'),
        meta: { trans_index: 102, keepAlive: true }
    },

    // 租车
    {
        path: '/BuyCar',
        name: 'car',
        component: () => import('@/views/Car/BuyCar.vue'),
        meta: { trans_index: 51 }
    },

    // 聚合订票
    {
        path: '/Polymerization',
        name: 'polymerization',
        component: () => import('@/views/Polymerization/Polymerization.vue'),
        meta: { trans_index: 61 }
    },

    // 行程
    // {
    //     path: '/OrderInfo',
    //     name: 'orderInfo',
    //     component: () => import('@/views/Plan/OrderInfo.vue'),
    //     meta: { trans_index: 51 }
    // },
    // {
    //     path: '/OrderInfoChange',
    //     name: 'orderInfoChange',
    //     component: () => import('@/views/Plan/OrderInfoChange.vue'),
    //     meta: { trans_index: 52 }
    // },
];


const router = new VueRouter({
    routes
});


router.beforeEach((to, from, next) => {
    //如果路由里面有meta属性，那么设置该网页title
    window.setTitle(null);

    if (to.path === '/') {
        next()
    } else {
        if (util.szhtDecrypt(window.sessionStorage.getItem("szht_mobile_ct")) === "") {
            //没有用户信息 TODO
            // next({name: '/', params: {msg: "请重新登录！"}})
            next()
        } else {
            next()
        }
    }

});

export default router
