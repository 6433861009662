/**
 * 火车相关信息
 */
import { getTrainTicket } from "@/api/train"
import { DiffDate, AddDate } from "@/services/date"
import moment from "moment";

const state = {
  ticket: {}, // 选择的列车信息
  orderInfo: { // 订票信息
    date: '', // 出发日期
    trainFromStation: "", // 出发站
    trainToStation: "", // 终点站
    trainType: '不限车型' //车次类型
  },
  ticketList: [], // 车票列表
  trainchange: {}, // 改签车票
  originalOrderNo: '', // 改签时的原单
  originalprice: '', //原单价格
  tempTime: [], // 切换城市，时间也要切换，这个是时间的缓存
}

const mutations = {
  SET_TICKET: (state, info) => {
    state.ticket = info
  },
  SET_TICKETLIST: (state, info) => {
    state.ticketList = info
  },
  SET_ORDER: (state, info) => {
    let dif = DiffDate(new Date(), info.date)
    if (dif > 28) info.date = AddDate(new Date(), 28)
    state.orderInfo = info
  },
  SET_FROM: (state, info) => {
    state.orderInfo.trainFromStation = info
  },
  SET_TO: (state, info) => {
    state.orderInfo.trainToStation = info
  },
  SET_TCHANGE: (state, info) => {
    state.trainchange = info
  },
  SET_originalOrderNo: (state, info) => {
    state.originalOrderNo = info
  },
  SET_originalprice: (state, info) => {
    state.originalprice = parseFloat(info)
  },
  SET_tempTime: (state, info) => {
    state.tempTime = info
  },
}

const actions = {
  SETTEMPTIME({ commit }, info) {
    let dif1 = DiffDate(new Date(), info[0])
    let dif2 = DiffDate(new Date(), info[1])
    if (dif1 > 28) info[0] = AddDate(new Date(), 28)
    if (dif1 < 0) info[0] = AddDate(new Date(), 1)
    if (dif2 > 29) info[1] = AddDate(info[0], 1)
    if (dif2 < 0) info[1] = AddDate(info[0], 1)
    commit('SET_tempTime', info)
  },
  // 设置火车票
  setTicket({ commit }, info) {
    commit('SET_TICKET', info)
  },
  // 列表相关
  setTicketList({ commit }, info) {
    commit('SET_TICKETLIST', info)
  },
  /**
   * ajax拉取车票列表，成功和失败的钩子用async来接
   * @param {*} param0 
   * @param {function} commonCB 成功和失败都会触发的回调函数 
   */
  fetchTicketList({ commit, state }) {
    return new Promise(function (resolve, reject) {
      getTrainTicket({
        FromCityName: state.orderInfo.trainFromStation,
        ToCityName: state.orderInfo.trainToStation,
        FromDate: state.orderInfo.date,
        Corp: "大唐"
      }).then(response => {
        let list = OrganizeList(response)
        commit('SET_TICKETLIST', list)
        resolve(list)
      }).catch(error => {
        commit('SET_TICKETLIST', [])
        resolve([])
      })
    })
  },
  // 订单基本信息相关
  setOrderInfo({ commit }, info) {
    info.date = moment(info.date).format()
    commit('SET_ORDER', info)
  },
  cleanOrderInfo({ commit }) {
    commit('SET_ORDER', {
      date: '', // 出发日期
      trainFromStation: "", // 出发站
      trainToStation: "", // 终点站
      trainType: '不限车型' //车次类型
    })
  },
  setOrderFrom({ commit }, info) {
    if (info.hasOwnProperty('name')) info = info.name
    commit('SET_FROM', info)
  },
  setOrderTo({ commit }, info) {
    if (info.hasOwnProperty('name')) info = info.name
    commit('SET_TO', info)
  },
  setTrainChange({ commit }, info) {
    let order = {
      date: info.order.planBeginDate,
      trainFromStation: info.order.fromStation,
      trainToStation: info.order.toStation,
      trainType: '不限车型'
    }
    commit('SET_ORDER', order)
    commit('SET_TCHANGE', info)
  },
  // 设置改签原单
  setOriginalOrderNo({ commit }, info) {
    commit('SET_originalOrderNo', info)
  },
  setOriginPrice({ commit }, info) {
    commit('SET_originalprice', info)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

/**
 * 整理车票列表
 * @param {Array} arr 
 */
function OrganizeList(arr = []) {
  let dataArr = []
  arr.forEach(element => {
    try {
      element.data.map(item => {
        if (item.hasOwnProperty('displayPrice') && item.displayPrice == "") {
          item.displayPrice = item.displaySeat[0].seatPrice
        }
        if (item.hasOwnProperty('arriveDays') && item.arriveDays == "") {
          item.arriveDays = DiffDate(item.trainBeginDate + "T" + item.startTime, item.trainEndDate + "T" + item.arriveTime).toString()
        }
        return item
      })
      dataArr.push(...element.data)
    } catch (error) { }
  })
  return dataArr
}

